import { Routes, Route, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import { usePusherWrapper } from "./PusherWrapper";
import { PusherContext } from "./Context";
import constants from "./constants.json";
import Cookies from "js-cookie";

function Admin() {
  // Custom Hook that inititializes the pusher object.
  const navigate = useNavigate();

  const pusherOBJ = usePusherWrapper();
  const [pusher, setPusher] = useState(null);
  const [isPusherInit, setIsPusherInit] = useState(false);
  const [channelPublic, setChannelPublic] = useState(null);
  const [username, setUsername] = useState(null);
  const [statusState, setStatusState] = useState("red");
  const [step, setStep] = useState(null);
  const [questionSet, setQuestionSet] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [adminChannels, setAdminChannels] = useState([]);
  const [teamsAnswers, setTeamsAnswers] = useState([]);
  const [teams, setTeams] = useState(constants.teamsInitial);
  const [color, setColor] = useState("#cb6505");

  useEffect(() => {
    if (pusher) {
      if (Cookies.get("token")) {
        pusher.signin();
        pusher.bind("pusher:signin_success", () => {
          setIsAuthenticated(true);
        });
      }
    }
  }, [isPusherInit]);

  useEffect(() => {
    if (isAuthenticated) {
      let adminChannelArray = [];
      let updatedScoreboard = {};
      teams.map((team) => {
        adminChannelArray.push(pusher.subscribe(`presence-${team.name}`))
        updatedScoreboard[`presence-${team.name}`] = {}
        return null;
      });
      setTeamsAnswers(updatedScoreboard)
      setAdminChannels(adminChannelArray);
      setStatusState("connected");
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (pusherOBJ) {
      setChannelPublic(pusherOBJ.subscribe("public-channel"));
      setPusher(pusherOBJ);
      setIsPusherInit(true);
    }
  }, [pusherOBJ]);

  useEffect(() => {
    if (username) {
      pusher.signin();
      pusher.bind("pusher:signin_success", () => {
        setIsAuthenticated(true);
      });
    }
  }, [username]);

  // bind handler for public channel
  useEffect(() => {
    if (channelPublic && isPusherInit) {
      channelPublic.bind("pusher:subscription_succeeded", () => {
        setStatusState("connecting");
        channelPublic.bind("question", (data) => setQuestionSet({ ...data }));
        channelPublic.bind("logout", () => handleLogOut());
        channelPublic.bind("trigger-step", (data) => setStep(data.step));
        channelPublic.bind("background-color", (data) => setColor(data.color));
        channelPublic.bind("step", (data) => setStep(data.step));
      });
    }
  }, [channelPublic, isPusherInit]);


  useEffect(() => {
    if (adminChannels.length > 0) {
      adminChannels.map((channel) => {     
        channel.bind("pusher:member_added", (member) => {
          
          //setChannelMembers({ ...channelMembers });
        });
        channel.bind("pusher:member_removed", (member) => {
          
          //setChannelMembers({ ...channelMembers });
        });
        channel.bind("client-answerset", (data) => {
            let newteamsAnswers = { ...teamsAnswers };
            newteamsAnswers[channel.name][data.member_id] = {
              answer: data.answer,
              time: data.time,
            };
            setTeamsAnswers(() => ({ ...newteamsAnswers }));   
        });
        return null
      })
    }
  }, [adminChannels]);

  const handleLogOut = () => {
    setIsPusherInit(null);
    setTeams(null);
    setUsername(null);
    setAdminChannels([]);
    pusher.disconnect(); 
    Cookies.remove("token");
    Cookies.remove("team");
    Cookies.remove("username");
    navigate("/login");
    document.location.reload();
  };

  return (
    <PusherContext.Provider
      value={{
        step,
        setUsername,
        statusState,
        handleLogOut,
        teamsAnswers,
        questionSet,
        color
      }}
    >
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/login" element={<Login />} />
      </Routes>
    </PusherContext.Provider>
  );
}

export default Admin;

import React, { useState, useContext } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MainListItems from "../components/MainListItems";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import axios from "axios";
import Chip from "@mui/material/Chip";
import Cookies from "js-cookie";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import constants from "../constants.json";
import CardContent from "@mui/material/CardContent";

import { PusherContext } from "../Context";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        WEBRTCSRUS EXPERIENCES
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}



const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {
  const { step, statusState, teamsAnswers, questionSet, color } =
    useContext(PusherContext);

  const ConnectionStatus = () => {
    switch (statusState) {
      case "initialized":
        return <ErrorOutlineIcon color="secondary" />;
      case "connected":
        return <CheckCircleOutlineIcon color="success" />;
      case "connecting":
        return <WarningAmberIcon color="secondary" />;
      default:
        return null;
    }
  };

  const [open, setOpen] = useState(false);
  const [question, setQuestion] = useState(1);
  const [lockAnswer, setLockAnswer] = useState(step !== "answer");
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const triggerQuestion = () => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/question?q=${question}`);
    setLockAnswer(!lockAnswer);
  };

  const triggerAnswer = () => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/answer`, {
      question,
      teamsAnswers,
    });
    setQuestion(question + 1);
    setLockAnswer(!lockAnswer);
  };

  const triggerDeleteUsers = () => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/delete-users`);
  };

  const resetQuestions = () => {
    setQuestion(1);
  };

  const triggerColorChange = () => {
    axios.defaults.withCredentials = true;
    axios.get(`${process.env.REACT_APP_SERVER_URL}/change-color`);
  };

  const triggerButtonForLogin = () => {
    axios.defaults.withCredentials = true;
    axios.get(`${process.env.REACT_APP_SERVER_URL}/trigger-step?step=login`);
  };

  const triggerButtonForWelcome = () => {
    axios.defaults.withCredentials = true;
    axios.get(`${process.env.REACT_APP_SERVER_URL}/trigger-step?step=welcome`);
  };

  const triggerButtonForFinal = () => {
    axios.defaults.withCredentials = true;
    axios.get(`${process.env.REACT_APP_SERVER_URL}/final`);
  };

  const RevealFinalist = () => {
    axios.defaults.withCredentials = true;

    const revealStages = [
      "none",
      "third",
      "second",
      "first",
      "credits",
      "lineup",
    ];
    let newStep;
    if (step === "final") {
      newStep = revealStages[0];
    }

    if (step === "none") {
      newStep = revealStages[1];
    }
    if (step === "third") {
      newStep = revealStages[2];
    }
    if (step === "second") {
      newStep = revealStages[3];
    }
    if (step === "first") {
      newStep = revealStages[4];
    }
    if (step === "credits") {
      newStep = revealStages[0];
    }

    axios.get(
      `${process.env.REACT_APP_SERVER_URL}/trigger-step?step=${newStep}`
    );
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              WEBRTCSRUS
            </Typography>

            <IconButton
              aria-label="status"
              sx={{
                backgroundColor: "white",
                borderRadius: "50%",
              }}
            >
              {ConnectionStatus()}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
                <Stack spacing={2} direction="column">
                  <Button
                    variant="contained"
                    onClick={triggerColorChange}
                    color="secondary"
                  >
                    Color Change 
                    {color && (
                      <Chip
                        label={color}
                        style={{ marginLeft: '20px', backgroundColor: color, color: "#FFFFFF" }}
                      />
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={triggerButtonForWelcome}
                    color="secondary"
                  >
                    Button for Welcome
                  </Button>
                  <Button
                    variant="contained"
                    onClick={triggerButtonForLogin}
                    color="secondary"
                  >
                    Button for Login
                  </Button>

                  <Button
                    variant="contained"
                    onClick={triggerQuestion}
                    disabled={!lockAnswer}
                  >
                    Next Question
                  </Button>
                  <Button
                    variant="contained"
                    onClick={triggerAnswer}
                    disabled={lockAnswer}
                  >
                    Display Answer
                  </Button>
                  <Button
                    variant="contained"
                    onClick={triggerButtonForFinal}
                    color="success"
                  >
                    Final
                  </Button>
                  <Button
                    variant="contained"
                    onClick={RevealFinalist}
                    color="success"
                  >
                    Reveal Finalist
                  </Button>
                  <Button
                    variant="contained"
                    onClick={resetQuestions}
                    color="error"
                  >
                    Reset questions
                  </Button>
                  <Button
                    variant="contained"
                    onClick={triggerDeleteUsers}
                    color="error"
                  >
                    Clear Out Users
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} md={9} lg={6}>
                {questionSet && (
                  <Grid xs={12} style={{overflow: 'scroll', height:'calc(100vh - 100px'}}>
                    {constants.questions.map((question) => {
                      return (
                        <Box sx={{ minWidth: 275, marginBottom: "15px" }}>
                          <Card variant="outlined" gutterBottom sx={{borderColor: question.question === questionSet.question
                                        ? "#2e7d32"
                                        : "#d32f2f",
                                        backgroundColor:  question.question === questionSet.question  ? "#38b83e82"
                                        : "#FFFFFF",}}>
                            <CardContent>
                              <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                              >
                                {question.question}
                              </Typography>
                              {question.answers.map((answer) => (
                                <Chip
                                  label={answer}
                                  style={{
                                    backgroundColor:
                                      question.correctAnswer === answer
                                        ? "#2e7d32"
                                        : "#d32f2f",
                                    color: "#FFFFFF",
                                  }}
                                />
                              ))}
                            </CardContent>
                          </Card>
                        </Box>
                      );
                    })}
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} md={9} lg={3}>
                <Typography variant="h6" gutterTop gutterBottom>
                  Users
                </Typography>
               
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

import React, { useContext } from "react";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import { Link } from "react-router-dom";
import { PusherContext } from "../Context";

  const MainListItems = () => { 
  
  const {
    handleLogOut
  } = useContext(PusherContext);
  
  return ( <React.Fragment>
    <ListItemButton component={Link} to="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton component={Link} to="/people">
      <ListItemIcon>
        <PeopleIcon  />
      </ListItemIcon>
      <ListItemText primary="People" />
    </ListItemButton>
    <ListItemButton onClick={handleLogOut}>
      <ListItemIcon >
        <LogoutIcon  />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItemButton>
  </React.Fragment>
)};
export default MainListItems